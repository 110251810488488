import Footer from "../components/footer";
import Header from "../components/header";
import { useEffect, useState } from "react";
import ApiUrl from "../Api/Api";
import { Link } from "react-router-dom";
import axios from "axios";

function Formation() {
  const [slider, setSlider] = useState([]);
  const [content, setContent] = useState([]);

  const fetchSlider = () => {
    axios
      .get(`${ApiUrl}/get/slidebar`)
      .then((response) => {
        setSlider(response.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    axios.get(`${ApiUrl}/get/Pages`)
      .then(response => {
        const latestNews = response?.data?.data.filter(item => item.id === 5);
        setContent(latestNews);
      })
      .catch(error => {
        console.error('Error fetching news:', error);
      });
  }, []);

  useEffect(() => {
    fetchSlider();
  }, []);



  return (
    <>
      <Header />
      <div className="home-mother">
        <div className="container" style={{ padding: "30px" }}>
          <h3 className="entry-title motherhouse">
            <a href="/formation">Formation</a>
          </h3>
          <div className="brudcrums">
            <Link to={"/"}>Home &nbsp;»&nbsp;{" "}</Link>
            <span className="pagename">Formation</span>
          </div>
        </div>
      </div>

      <div className="container subpage">
        <h3 className="heading">Formation</h3>
        <div className="row">
          <div className="col-lg-9">
            <div>
              <div className="bs-exercis mt-5">
                {content?.length > 0 ? (
                  content?.map((item) => (
                    <p style={{ marginTop: "20px" }} key={item.id} dangerouslySetInnerHTML={{ __html: item.content }}>
                    </p>
                  ))
                ) : (
                  <p style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>No data available</p>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-3 sidebar">
            {slider.map((item) => (
              <div className="slidedata" key={item.id}>
                <img src={item.image} alt="" />
                <strong>{item.title} </strong>
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                <br /> <br />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Formation;
