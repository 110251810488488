import Home from "./home";
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Mhouse from "./pages/Mhouse";
import Founder from "./pages/founder";
import ProvincialCouncil from "./pages/provincialcouncil";

import History from "./pages/history";
import Motto from "./pages/motto";
import Administration from "./pages/administration";
import Fac from "./pages/fac";
import Formation from "./pages/formation";
import Candidacy from "./pages/candidacy";
import Postulancy from "./pages/postulancy";
import Novitiate from "./pages/novitiate";
import Juniorate from "./pages/juniorate";

import Calendar from "./pages/Calendar";

import NewsLetter from "./pages/NewsLetter";

import VocationPromotion from "./pages/vocationpromotion";
import Contact from "./pages/contact";
import Gallery from "./pages/gallery";

import Eventscelebrations from "./pages/footerpage/eventscelebrations";
import Obitury from "./pages/footerpage/obituary";
import OverseasMission from "./pages/footerpage/overseasmission";
import SCBAssociates from "./pages/footerpage/scbassociates";
import Statistics from "./pages/footerpage/statistics";
import SignificantDates from "./pages/footerpage/significantdates";
import Location from "./pages/footerpage/location";
import Videos from "./pages/footerpage/videos";
import NewsEvents from "./pages/NewsEvents";
import ProvincialProgram from "./pages/ProvincialProgram";
import Tab from "./pages/Tab";
import Newtab from "./pages/Newtab";
import axios from "axios";

import { ReligioUrl } from "./components/API/Api";

function App() {
  const [data, setContent] = useState([]);
  const [description, setDescription] = useState([]);
  useEffect(() => {
    axios
      .get(`${ReligioUrl}/houses/province/2 `)
      .then((response) => {
        setContent(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching news:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${ReligioUrl}/commission/province/2`)
      .then((response) => {
        setDescription(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching description:", error);
      });
  }, []);
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/motherhouse" element={<Mhouse />} />
        <Route path="/founder" element={<Founder />} />
        <Route path="/provincial-council" element={<ProvincialCouncil />} />
        <Route path="/history" element={<History />} />
        <Route path="/the-motto-of-the-province" element={<Motto />} />
        <Route path="/newsletter" element={<NewsLetter />} />
        <Route path="/general-administration" element={<Administration />} />

        {data?.map((item) => (
          <Route
            path={`/${item.name.split(" ")[0]}`}
            key={item.id}
            element={<Tab />}
          />
        ))}

        {description?.map((item) => (
          <Route
            path={`/${item.name.split("(")[0]}`}
            key={item.id}
            element={<Fac />}
          />
        ))}

        <Route path="/calendar" element={<Calendar />} />

        {/* <Route path="/cosaf" element={<Cosaf />} /> */}
        {/*   <Route path="/fac" element={<Fac />} />
        <Route path="/msp" element={<Msp />} />
        <Route path="/ecep" element={<Ecep />} /> */}

        <Route path="/formation" element={<Formation />} />
        <Route path="/candidacy" element={<Candidacy />} />
        <Route path="/postulancy" element={<Postulancy />} />
        <Route path="/novitiate" element={<Novitiate />} />
        <Route path="/juniorate" element={<Juniorate />} />
        <Route path="/vocation-promotion" element={<VocationPromotion />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route
          path="/events-and-celebrations"
          element={<Eventscelebrations />}
        />
        <Route path="/obitury" element={<Obitury />} />
        <Route path="/overseas-mission" element={<OverseasMission />} />
        <Route path="/scb-associates" element={<SCBAssociates />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/significant-dates" element={<SignificantDates />} />
        <Route path="/location" element={<Location />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/news-events" element={<NewsEvents />} />
        <Route path="/provincial-programs" element={<ProvincialProgram />} />
        <Route path="/communityprofile" element={<Tab />} />
        <Route path="/profile" element={<Tab />} />
        <Route path="/newtab" element={<Newtab />} />
      </Routes>
    </>
  );
}

export default App;
