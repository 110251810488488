import React, { useEffect, useState } from 'react';
import ApiUrl from '../Api/Api';
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './Slider.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Slider = () => {
  const [slider, setSlider] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [mode, setMode] = useState("online");

  useEffect(() => {
    const fetchSlider = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/slider`);
        setSlider(response.data.data);
        localStorage.setItem("slider", JSON.stringify(response.data.data));
        if (mode === "offline") {
          toast.success("Back online!");
        }
      } catch (error) {
        if (mode !== "offline") {
          let slider = JSON.parse(localStorage.getItem("slider"));
          setSlider(slider);
          toast.error("You are in offline mode");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchSlider();
  }, [mode]);

  return (
    <div className="carousel-container">
      <ToastContainer />
      {loading ? (
        <div className="skeleton-loading">
          <div className="skeleton-slide"></div>
          <div className="skeleton-slide"></div>
          <div className="skeleton-slide"></div>
        </div>
      ) : (
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          infiniteLoop={true}
          autoPlay={true}
          interval={5000}
          stopOnHover={true}
          transitionTime={2000}
          swipeable={true}
          showArrows={true}
          className="carousel"
        >
          {slider?.map((item) => (
            <div key={item?.id} className="slide">
              <img src={item?.image} alt='slider' />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
}

export default Slider;
