import Header from "../../components/header";
import Footer from "../../components/footer";
import { useEffect, useState } from "react";
import ApiUrl from "../../Api/Api";
import { Link } from "react-router-dom";
import axios from "axios";


function Videos() {
  const [slider, setslider] = useState([]);
  const [videos, setVideos] = useState([]);

  const fetchslide = () => {
    fetch(`${ApiUrl}/get/slidebar`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        setslider(resp.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const fetchYouTubeVideos = () => {
    const apiKey = 'AIzaSyDVDBYVsPMXWb_ZbTd7_Wux0zK2z64KySU';
    const channelId = 'UCA1LW4DTtOJWEI54otfda-g';

    axios.get(`https://www.googleapis.com/youtube/v3/search`, {
      params: {
        key: apiKey,
        part: 'snippet',
        channelId: channelId,
        maxResults: 12,
        type: 'video',
      },
    })
      .then((response) => {
        setVideos(response.data.items);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchslide();
    fetchYouTubeVideos();
  }, []);


  return (
    <>
      <Header />
      <div className="home-mother">
        <div className="container" style={{ padding: "30px" }}>
          <h3 className="entry-title motherhouse">
            <a href="/videos">Videos</a>
          </h3>
          <div className="brudcrums">
            <Link to={"/"}>Home &nbsp;»&nbsp;{" "}</Link>
            <span className="pagename">Videos</span>
          </div>
        </div>
      </div>

      <div className="container subpage">
        <h3 className="heading">Videos</h3>
        <div className="row">
          <div className="col-lg-9">
            <div>
              <h5>Video Gallery</h5>
              <div className="row">
                {videos.map((video) => (
                  <div key={video.id.videoId} className="col-md-3 mb-4">
                    <iframe
                      width="100%"
                      height="150"
                      src={`https://www.youtube.com/embed/${video.id.videoId}`}
                      title={video.snippet.title}
                      frameBorder="0"
                      allowFullScreen
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-lg-3 sidebar">
            {slider.map((item) => (
              <div className="slidedata" key={item.id}>
                <img src={item.image} alt="" />
                <strong>{item.title} </strong>
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                <br /> <br />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Videos;
