import React, { useState, useEffect } from 'react';
import About from "./components/about";
import Churchstatic from "./components/churchstatic";
import Footer from "./components/footer";
import Header from "./components/header";
import Ourstatic from "./components/ourstatic";
import Scrollbar from "./components/scrollbar";
import Slider from "./components/slider";
import Upcoming from "./components/upcoming";
import Loader from './Loader';

function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Header />
      <Slider />
      <Scrollbar />
      <About />
      <Ourstatic />
      <Churchstatic />
      <Upcoming />
      <Footer />
    </div>
  );
}

export default Home;
