import Footer from "../components/footer";
import Header from "../components/header";
import { useEffect, useState } from "react";
import ApiUrl from "../Api/Api";
import { Link } from "react-router-dom";
import axios from "axios";

function Administration() {
  const [slider, setslider] = useState([]);
  const [generalcontent, setgeneralContent] = useState([]);
  const [data, setData] = useState([]);

  const fetchslide = () => {
    fetch(`${ApiUrl}/get/slidebar`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        setslider(resp.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    axios.get(`${ApiUrl}/get/Pages`)
      .then(response => {
        const latestNews = response?.data?.data.filter(item => item.id === 4);
        setgeneralContent(latestNews);
      })
      .catch(error => {
        console.error('Error fetching news:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(`${ApiUrl}/get/post`)
      .then(response => {
        const filteredData = response?.data?.data.filter(item => item.category_name === "General Administration");
        filteredData.forEach(item => {
          item.content = item.content.replace(/<[^>]*>/g, '');
        });

        setData(filteredData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);


  useEffect(() => {
    fetchslide();
  }, []);

  return (
    <>
      <Header />
      <div className="home-mother">
        <div className="container" style={{ padding: "30px" }}>
          <h3 className="entry-title motherhouse">
            <a href="/general-administration">General Administration</a>
          </h3>
          <div className="brudcrums">
            <Link to={"/"}>Home &nbsp;»&nbsp;{" "}</Link>
            <span className="pagename">General Administration</span>
          </div>
        </div>
      </div>

      <div className="container">
        <h3 className="heading">General Administration</h3>
        <div className="row">
          <div className="col-lg-9">
            <div className="row adminpage">
              <h3>SISTERS OF ST. CHARLES BORROMEO - THE DAWN OF A NEW ERA</h3>
              <div className="col-lg-6">
                {/* <img
                  src="images/subpage/avator.jpg"
                  style={{ borderRadius: " 50%" }}
                  alt=""
                /> */}
              </div>
              <div className="col-lg-6">
                {/* <img
                  src="images/subpage/avator.jpg"
                  style={{ borderRadius: " 50%" }}
                  alt=""
                /> */}
              </div>
              {generalcontent?.length > 0 ? (
                generalcontent?.map((item) => (
                  <p key={item.id} dangerouslySetInnerHTML={{ __html: item.content }}>
                  </p>
                ))
              ) : (
                <p style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>No data available</p>
              )}
            </div>
            <h2>Superior General and Councillors</h2>
            <br />
            <div className="Container General">
              <table>
                <tbody>
                  <tr>
                    {data?.map(item => (
                      <td key={item.title}>
                        <img src={item.image} alt={item.title} style={{ width: "100%",height: "200px", objectFit: "cover", borderRadius: "10px", marginBottom: "10px",padding: "10px" }} loading="lazy" />
                        <center>
                          <strong>{item.content}</strong>
                          <p>{item.title}</p>
                        </center>
                        &nbsp;
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-3 sidebar">
            {slider.map((item) => (
              <div className="slidedata" key={item.id}>
                <img src={item.image} alt="" />
                <strong>{item.title} </strong>
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                <br /> <br />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Administration;
