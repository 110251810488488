import React, { useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import axios from "axios";
import ApiUrl from "../Api/Api";

const imageStyles = {
  width: "190px",
  borderRadius: "10%",
};

const imageStyles1 = {
  width: "190px",
  borderRadius: "10%",
};

const boxStyles1 = {
  boxShadow: "0px 7px 16px 0px rgba(172 ,153 ,153, 0.5)",
  padding: "7px 25px 9px 22px",
  backgroundColor: "#fff",
};

const VerticalTabs2 = () => {
  const [activeTab1, setActiveTab1] = useState("Provincial");
  const [activeTab2, setActiveTab2] = useState("Superior General");
  const [tabData, setTabData] = useState([]);
  const [tabData2, setTabData2] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleTabSelect1 = (selectedTab) => {
    setActiveTab1(selectedTab);
  };

  function stripHtmlTags(html) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${ApiUrl}/get/post`)
      .then((response) => {
        const filteredData = response?.data?.data.filter(
          (item) => item.category_name === "Provincial Council"
        );
        const sanitizedData = filteredData.map((item) => ({
          ...item,
          content: stripHtmlTags(item.content),
        }));
        setTabData(sanitizedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${ApiUrl}/get/post`)
      .then((response) => {
        const filteredData = response?.data?.data.filter(
          (item) => item.category_name === "General Administration"
        );
        const sanitizedData = filteredData.map((item) => ({
          ...item,
          content: stripHtmlTags(item.content),
        }));
        setTabData2(sanitizedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const handleTabSelect2 = (selectedTab) => {
    setActiveTab2(selectedTab);
  };

  useEffect(() => {
    setActiveTab1("Provincial");
    setActiveTab2("Superior General");
  }, []);

  return (
    <div
      className="container-fluid py-3"
      style={{ backgroundColor: "#f3f3f3" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-12">
            <div style={boxStyles1}>
              <Tab.Container activeKey={activeTab1} onSelect={handleTabSelect1}>
                <div className="section-heading">
                  <h2 className="entry-title">Provincial Council</h2>
                </div>
                <div className="entry-content">
                  <div className="row">
                    <div className="col-md-7 col-lg-6">
                      <Nav variant="pills" className="flex-column">
                        {tabData.map((item) => (
                          <Nav.Item key={item.title}>
                            <Nav.Link
                              eventKey={item.title}
                              className={`nav-link mb-3 p-3 shadow ${activeTab1 === item.title ? "active" : ""
                                }`}
                              style={{
                                fontFamily: "Arial, sans-serif",
                                fontSize: "15px",
                                fontWeight: "bold",
                                transition: "color 0.3s ease",
                              }}
                            >
                              {item.title}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </div>
                    <div className="col-md-5 col-lg-6">
                      {loading ? (
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="tab-image">
                          <img
                            src={
                              tabData.find((item) => item.title === activeTab1)
                                ?.image
                            }
                            alt={activeTab1}
                            style={imageStyles}
                          />
                          <p
                            style={{
                              marginLeft: "15px",
                              marginTop: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            <span style={{ fontSize: "19px", color: "black" }}>
                              {
                                tabData.find((item) => item.title === activeTab1)
                                  ?.content
                              }
                            </span>
                            <br />
                            <span style={{ fontSize: "15px", color: "red" }}>
                              {activeTab1}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Tab.Content>
                  {tabData.map((item) => (
                    <Tab.Pane
                      eventKey={item.title}
                      key={item.title}
                      className="tab-pane fade"
                      id={item.title}
                    >
                      {item.content}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
          {/* 2nd tab */}
          <div className="col-md-6 col-12">
            <div style={boxStyles1}>
              <Tab.Container activeKey={activeTab2} onSelect={handleTabSelect2}>
                <div className="section-heading">
                  <h2 className="entry-title">General Administration</h2>
                </div>
                <div className="entry-content">
                  <div className="row">
                    <div className="col-md-7 col-lg-6">
                      <Nav variant="pills" className="flex-column">
                        {tabData2?.map((item) => (
                          <Nav.Item key={item.title}>
                            <Nav.Link
                              eventKey={item.title}
                              className={`nav-link mb-3 p-3 shadow ${activeTab2 === item.title ? "active" : ""
                                }`}
                              style={{
                                fontFamily: "Arial, sans-serif",
                                fontSize: "15px",
                                fontWeight: "bold",
                                transition: "color 0.3s ease",
                              }}
                            >
                              {item.title}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </div>
                    <div className="col-md-5 col-lg-6">
                      {loading ? (
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="tab-image">
                          <img
                            src={
                              tabData2.find((item) => item.title === activeTab2)
                                ?.image
                            }
                            alt={activeTab2}
                            style={imageStyles1}
                          />
                          <p
                            style={{
                              marginLeft: "15px",
                              marginTop: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            <span style={{ fontSize: "19px", color: "black" }}>
                              {
                                tabData2.find((item) => item.title === activeTab2)
                                  ?.content
                              }
                            </span>
                            <br />
                            <span style={{ fontSize: "15px", color: "red" }}>
                              {activeTab2}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Tab.Content>
                  {tabData2.map((item) => (
                    <Tab.Pane
                      eventKey={item.title}
                      key={item.title}
                      className="tab-pane fade"
                      id={item.title}
                    >
                      {item.content}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerticalTabs2;
