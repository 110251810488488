import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { ApiUrl } from "../../components/API/Api";

function Statistics() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/Pages`);
        const filteredData = response?.data?.data.filter(item => item.id === 11);
        setData(filteredData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <Header />
      <div className="home-mother">
        <div className="container" style={{ padding: "30px" }}>
          <h3 className="entry-title motherhouse">
            <a href="/statistics">Statistics</a>
          </h3>
          <div className="brudcrums">
            <Link to={"/"}>Home &nbsp;»&nbsp;{" "}</Link>
            <span className="pagename">Statistics</span>
          </div>
        </div>
      </div>

      <div className="container subpage">
        <h3 className="heading">Statistics</h3>
        <div className="row">
          <div className="">
            {data?.map((item, index) => (
              <div key={index}>
                {item?.content && (
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Statistics;
