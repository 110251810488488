import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import ApiUrl from "../../Api/Api";
import axios from "axios";
import { useEffect, useState } from "react";

function SignificantDates() {
  const [missionData, setMissionData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/Pages`);
        setMissionData(response?.data?.data);
      } catch (error) {
        console.error('Error fetching mission data:', error);
      }
    };

    fetchData();
  }, []);
  const filteredData = missionData.filter(item => item.id === 14);
  return (
    <>
      <Header />
      <div className="home-mother">
        <div className="container" style={{ padding: "30px" }}>
          <h3 className="entry-title motherhouse">
            <a href="/significant-dates">Significant Dates</a>
          </h3>
          <div className="brudcrums">
            <Link to={"/"}>Home &nbsp;»&nbsp;{" "}</Link>
            <span className="pagename">Significant Dates</span>
          </div>
        </div>
      </div>

      <div className="container subpage">
        <h3 className="heading">Significant Dates</h3>
        {filteredData.map((item, index) => (
          <div key={index}>
            {item.content && (
              <div dangerouslySetInnerHTML={{ __html: (item?.content) }} />
            )}
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
}
export default SignificantDates;
