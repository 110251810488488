import Header from "../../components/header";
import Footer from "../../components/footer";
import { useEffect, useState } from "react";
import ApiUrl from "../../Api/Api";
import { Link } from "react-router-dom";
import axios from "axios";
function SCBAssociates() {
  const [slider, setslider] = useState([]);
  const [missionData, setMissionData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/Pages`);
        setMissionData(response?.data?.data);
      } catch (error) {
        console.error('Error fetching mission data:', error);
      }
    };

    fetchData();
  }, []);
  const fetchslide = () => {
    fetch(`${ApiUrl}/get/slidebar`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        setslider(resp.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetchslide();
  }, []);

  const filteredData = missionData.filter(item => item.id === 15);

  function removeTables(content) {
    return content.replace(/<table\b[^<]*>[\s\S]*?<\/table>/gi, '');
  }

  function keepTablesRemoveParagraphs(content) {
    return content.replace(/(<p\b[^<]*>[\s\S]*?<\/p>|<b\b[^<]*>[\s\S]*?<\/b>|<h5\b[^<]*>[\s\S]*?<\/h5>)/gi, '');
  }

  return (
    <>
      <Header />
      <div className="home-mother">
        <div className="container" style={{ padding: "30px" }}>
          <h3 className="entry-title motherhouse">
            <a href="/scb-associates">SCB Associates</a>
          </h3>
          <div className="brudcrums">
            <Link to={"/"}>Home &nbsp;»&nbsp;{" "}</Link>
            <span className="pagename">SCB Associates</span>
          </div>
        </div>
      </div>

      <div className="container subpage">
        <h3 className="heading">SCB Associates</h3>
        <div className="row">
          <div className="col-lg-9">
            <div>
              <div className="mt-5 justify">
                <div>
                  {filteredData.map((item, index) => (
                    <div key={index}>
                      {item.content && (
                        <p dangerouslySetInnerHTML={{ __html: removeTables(item.content) }} />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 sidebar">
            {slider.map((item) => (
              <div className="slidedata" key={item.id}>
                <img src={item.image} alt="" />
                <strong>{item.title} </strong>
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                <br /> <br />
              </div>
            ))}
          </div>
        </div>
        <div className="justify">
          {filteredData.map((item, index) => (
            <div key={index}>
              {item.content && (
                <div dangerouslySetInnerHTML={{ __html: keepTablesRemoveParagraphs(item?.content) }} />
              )}
            </div>
          ))}
          <h5>
            “Whoever remains in me and I in Him bears fruit in plenty.”Jn.15.5
          </h5>
          <p>
            A definite procedure is adapted for grafting the associates to the
            spirit of the Congregation. During the periodical meetings a
            deepening of the knowledge about our Congregation is done through
            in-put sessions and discussions on the following topic:
          </p>
          <ul>
            <li>Charism of our Congregation</li>
            <li>Brief history of our Founder and Patron</li>
            <li>Spirit of the Congregation</li>
            <li>Biographies of predecessors</li>
            <li>Our Crest and Logo.</li>
            <li>Our ministries in India and Overseas</li>
          </ul>
          <p>
            The involvement of the associates in our apostolate activities
            through
          </p>
          <ul>
            <li>
              Voluntary service at evening study centers for the poor students
            </li>
            <li>
              Visit to the sick and invalid in the locality and Government
              Hospitals.
            </li>
            <li>Job opportunities for the poor youth and widows</li>
            <li>Listening to the problems of people and praying for them.</li>
            <li>Getting involved in the parish activities.</li>
            <li>
              On certain occasions the associates are invited to join the local
              SCB communities for celebrations.
            </li>
          </ul>
          <h5>Significant milestones</h5>
          <ul>
            <li>
              Sr.Jaya, Sr.Ignatius and Sr.Lily K. attended a meeting of the
              animators and coordinators of S.C.B.Associates at Vakola in 2007.
            </li>
            <li>
              Sr.Jane participated in the meeting of the coordinators at
              Majiwade in 2010
            </li>
            <li>
              A meeting of the animators and coordinators of S.C.B.Associates
              from three provinces in India was held at Tirupattur on the 24th
              July 2010 in which 28 members took part.
            </li>
          </ul>
          <p>
            The Junior SCB Associates have been conceived and initiative is
            given to establish them in Madurai and Ranipet. Clarity of vision
            and effective functioning are in the process of being strengthened.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default SCBAssociates;
