import Header from "../../components/header";
import Footer from "../../components/footer";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ReligioUrl } from "../../components/API/Api";

function Obitury() {
  const [obituaryData, setObituaryData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${ReligioUrl}/member/province/death/2`)
      .then((response) => {
        setObituaryData(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching obituary data:", error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Header />
      <div className="home-mother">
        <div className="container" style={{ padding: "30px" }}>
          <h3 className="entry-title motherhouse">
            <a href="/obitury">Obituary</a>
          </h3>
          <div className="brudcrums">
            <Link to={"/"}>Home &nbsp;»&nbsp; </Link>
            <span className="pagename">Obituary</span>
          </div>
        </div>
      </div>

      <div className="container subpage">
        <h3 className="heading">Obituary</h3>
        {loading ? (
          <div className="text-center">
            <p>
              <b>Loading...</b>
            </p>
          </div>
        ) : (
          <div className="row">
            {obituaryData && obituaryData.length > 0 ? (
              obituaryData?.map((obituary, index) => (
                <div key={index} className="col-md-3 pb-3">
                  <div className="container">
                    <div className="pt-2 m-auto">
                      <div className="card card-custom bg-white border-white border-0">
                        <div
                          className="card-custom-img"
                          style={{
                            backgroundImage:
                              "url(images/all-img/CardBackgroudImage.jpg)",
                          }}
                        />
                        <div className="card-custom-avatar pb-4">
                          <img
                            className="img-fluid bg-white"
                            src={
                              obituary.image ||
                              "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-4.png"
                            }
                            alt="Avatar"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-4.png";
                            }}
                          />
                        </div>
                        <div
                          className="card-body text-center pt-4 p-0"
                          style={{ overflowY: "auto" }}>
                          <p className="card-title"> {obituary.member_name}</p>
                        </div>
                        <div
                          className="card-footer text-center"
                          style={{
                            background: "inherit",
                            borderColor: "inherit",
                          }}>
                          <p className="card-text">
                            <b>Died On :</b> {obituary.death_date}
                          </p>
                          <p className="card-text">
                            <b>DOB &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</b>
                            {obituary.dob}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-lg-12">
                <p>
                  <b>No Obituary Members Available</b>
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Obitury;
