import Header from "../../components/header";
import Footer from "../../components/footer";
import { useEffect, useState } from "react";
import ApiUrl from "../../Api/Api";
import { Link } from "react-router-dom";
import axios from "axios";
function OverseasMission() {
  const [slider, setslider] = useState([]);
  const [missionData, setMissionData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/Pages`);
        setMissionData(response?.data?.data);
      } catch (error) {
        console.error('Error fetching mission data:', error);
      }
    };

    fetchData();
  }, []);
  const fetchslide = () => {
    fetch(`${ApiUrl}/get/slidebar`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        setslider(resp.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetchslide();
  }, []);
  const filteredData = missionData.filter(item => item.id === 12);

  function removeTables(content) {
    return content.replace(/<table\b[^<]*>[\s\S]*?<\/table>/gi, '');
  }

  function keepTablesRemoveParagraphs(content) {
    return content.replace(/(<p\b[^<]*>[\s\S]*?<\/p>|<b\b[^<]*>[\s\S]*?<\/b>)/gi, '');
  }

  return (
    <>
      <Header />
      <div className="home-mother">
        <div className="container" style={{ padding: "30px" }}>
          <h3 className="entry-title motherhouse">
            <a href="/overseas-mission">Overseas Mission</a>
          </h3>
          <div className="brudcrums">
            <Link to={"/"}>Home &nbsp;»&nbsp;{" "}</Link>
            <span className="pagename">Overseas Mission</span>
          </div>
        </div>
      </div>

      <div className="container subpage">
        <h3 className="heading">Overseas Mission</h3>
        <div className="row">
          <div className="col-lg-9">
            <div>
              <div className="bs-callout mt-5 justify">
                <p style={{ marginTop: "20px" }}>
                  <div>
                    {filteredData.map((item, index) => (
                      <div key={index}>
                        {item.content && (
                          <p dangerouslySetInnerHTML={{ __html: removeTables(item.content) }} />
                        )}
                      </div>
                    ))}
                  </div>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 sidebar">
            {slider.map((item) => (
              <div className="slidedata" key={item.id}>
                <img src={item.image} alt="" />
                <strong>{item.title} </strong>
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                <br /> <br />
              </div>
            ))}
          </div>
        </div>
        <div>
          {filteredData.map((item, index) => (
            <div key={index}>
              {item.content && (
                <div dangerouslySetInnerHTML={{ __html: keepTablesRemoveParagraphs(item?.content) }} />
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
export default OverseasMission;
